/*
 *
 * Widget reducer
 *
 */
import localStorage from '@pisano/feedback-core/app/utils/localStorage';
import { addMinutes, isAfter } from 'date-fns';
import {
  ADD_PREVIOUS_FEEDBACK,
  CLOSE_WIDGET,
  HIDE_PREVIOUS_FEEDBACKS,
  HIDE_WIDGET,
  LOAD_CUSTOMER_SUCCESS,
  LOAD_WIDGET_CSS_SUCCESS,
  LOAD_WIDGET_ERROR,
  LOAD_WIDGET_SUCCESS,
  OPEN_CHAT,
  OPEN_WIDGET,
  OPEN_WIDGET_ONCE,
  PREVIOUS_FEEDBACKS,
  SET_WIDGET_INITIAL_DISPLAY_STATUS,
  SHOW_PREVIOUS_FEEDBACKS,
  SHOW_WIDGET,
} from './constants';
import { getWidgetStore, handleWidgetStore } from './helpers/widgetStores';

export const initialState = {
  chatPageOpen: false,
  customer: null,
  defaultLanguageCode: 'EN',
  displayBranding: true,
  displayedBefore: false,
  displayInterval: 0,
  displayOnce: false,
  displayOnceDelayPeriod: 0,
  displayOnExit: false,
  enabledDomains: [],
  enabledUrls: [],
  fontColor: '#FFFFFF',
  frameCss: null,
  innerCss: null,
  isDisplayOncePeriodExpired: false,
  isDomainEnabled: true,
  isEnabledOnMobile: true,
  isFullPageOnMobile: false,
  isLoaded: false,
  isModal: false,
  isMultipleFeedbackPeriodExpired: false,
  isOpen: false,
  isSelectingPreviousFeedback: false,
  isVisible: false,
  margin: 20,
  modalToggleText: {},
  multipleFeedbackDelayPeriod: 0,
  nodeId: null,
  popoverText: {},
  preventMultipleFeedback: false,
  previousFeedbacks: localStorage.getItem(PREVIOUS_FEEDBACKS) || [],
  primaryColor: '#20B8F7',
  radius: 0,
  secondaryColor: '#20B8F7',
  secureMode: false,
  shouldHideWidget: false,
  size: 60,
  webChannel: {},
  width: null,
  widthType: null,
};

function isWidgetDisplayTimeExpired(nodeId, displayOnceDelayPeriod) {
  const { widgetInitialDisplayTime, isWidgetDisplayedBefore } = getWidgetStore(nodeId);

  if (isWidgetDisplayedBefore && !!displayOnceDelayPeriod && widgetInitialDisplayTime) {
    const displayTime = new Date(widgetInitialDisplayTime);
    const added = addMinutes(displayTime, displayOnceDelayPeriod);
    const periodExpired = isAfter(new Date(), added);

    if (periodExpired) {
      const newWidgetInitialDisplayTime = new Date();
      handleWidgetStore(nodeId, {
        isWidgetDisplayedBefore: false,
        widgetInitialDisplayTime: newWidgetInitialDisplayTime,
      });
    }

    return periodExpired;
  }

  return true;
}

function isPreventMultipleFeedbackPeriodExpired(nodeId, multipleFeedbackDelayPeriod) {
  const { lastFeedbackSubmitDate, isWidgetDisplayedBefore } = getWidgetStore(nodeId);

  if (isWidgetDisplayedBefore && !!multipleFeedbackDelayPeriod && lastFeedbackSubmitDate) {
    const parsedLastFeedbackSubmitDate = new Date(lastFeedbackSubmitDate);
    const added = addMinutes(parsedLastFeedbackSubmitDate, multipleFeedbackDelayPeriod);
    const periodExpired = isAfter(new Date(), added);
    return periodExpired;
  }

  return true;
}

function setInitialStoreDisplayValues(nodeId) {
  handleWidgetStore(nodeId, {
    isWidgetDisplayedBefore: true,
    widgetInitialDisplayTime: new Date(),
  });
}

function widgetReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_WIDGET_SUCCESS:
      return { ...state, ...action.payload };
    case LOAD_WIDGET_CSS_SUCCESS:
      const { frameCss, innerCss } = action.payload;
      return { ...state, frameCss, innerCss };
    case LOAD_WIDGET_ERROR:
      return { ...state, error: action.payload };
    case LOAD_CUSTOMER_SUCCESS:
      return { ...state, customer: { ...state.customer, ...action.payload } };
    case SET_WIDGET_INITIAL_DISPLAY_STATUS: {
      const {
        nodeId,
        displayOnce,
        displayOnceDelayPeriod,
        preventMultipleFeedback,
        multipleFeedbackDelayPeriod,
        isVisible,
      } = state;

      let isDisplayOncePeriodExpired = true;

      if (displayOnce) {
        isDisplayOncePeriodExpired = isWidgetDisplayTimeExpired(nodeId, displayOnceDelayPeriod);
      }

      let isMultipleFeedbackPeriodExpired = true;

      if (preventMultipleFeedback) {
        isMultipleFeedbackPeriodExpired = isPreventMultipleFeedbackPeriodExpired(nodeId, multipleFeedbackDelayPeriod);
      }

      const isVisibleValue = isDisplayOncePeriodExpired && isMultipleFeedbackPeriodExpired && isVisible;

      if (isVisibleValue) {
        setInitialStoreDisplayValues(nodeId);
      }

      return {
        ...state,
        isDisplayOncePeriodExpired,
        isMultipleFeedbackPeriodExpired,
        isVisible: isVisibleValue,
        isLoaded: true,
      };
    }
    case OPEN_WIDGET:
      return { ...state, isOpen: true, displayedBefore: true };
    case OPEN_WIDGET_ONCE: {
      const { nodeId, displayOnce, displayOnceDelayPeriod } = state;
      if (!displayOnce) {
        setInitialStoreDisplayValues(nodeId);
        return {
          ...state,
          isOpen: true,
          displayedBefore: true,
        };
      }
      const expired = isWidgetDisplayTimeExpired(nodeId, displayOnceDelayPeriod);
      if (expired) {
        handleWidgetStore(nodeId, {
          isWidgetDisplayedBefore: false,
        });
      }
      const { isWidgetDisplayedBefore } = getWidgetStore(nodeId);
      const isOpen = expired || !isWidgetDisplayedBefore;
      return {
        ...state,
        isOpen,
        displayedBefore: true,
      };
    }
    case CLOSE_WIDGET:
      return { ...state, isOpen: false };
    case OPEN_CHAT:
      return { ...state, feedbackId: action.payload.feedbackId, token: action.payload.token };
    case SHOW_WIDGET:
      return { ...state, isVisible: true };
    case HIDE_WIDGET:
      return { ...state, isVisible: false, isOpen: false };
    case ADD_PREVIOUS_FEEDBACK:
      return {
        ...state,
        previousFeedbacks: state.previousFeedbacks.concat(action.payload.feedback),
      };
    case SHOW_PREVIOUS_FEEDBACKS:
      return { ...state, isSelectingPreviousFeedback: true };
    case HIDE_PREVIOUS_FEEDBACKS:
      return { ...state, isSelectingPreviousFeedback: false };
    default:
      return state;
  }
}

export default widgetReducer;
